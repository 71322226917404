import { Form, FormProps, message } from 'antd'
import api from 'common/api'
import { ICreateOrder, IOrder } from 'common/types/order'
import { IProduct } from 'common/types/products'
import useLayoutContext from 'components/ui/layout/services/layoutContext'
import useConverter from 'hooks/useConverter'
import useCurrency from 'hooks/useCurrency'
import useUser from 'hooks/useUser'
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import useLocalStorageState from 'use-local-storage-state'

const Context = () => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [basket, setBasket] = useLocalStorageState<IProduct[]>('basket', {
    defaultValue: []
  })
  const [order, setOrder] = useState<IOrder>()
  const { user } = useUser()

  const {
    state: { currency }
  } = useLayoutContext()

  const { converter } = useConverter()

  const totalPrice: number = useMemo(
    () =>
      basket.reduce((acc, item) => {
        const product = item?.integration?.find(
          (product) =>
            product.size_id === item.selected_size &&
            product.color_id === item.selected_color
        )
        const price =
          converter(product?.price, product?.currency_id, currency) || 0
        return acc + (price ?? 0) * (item.count ?? 1)
      }, 0),
    [basket, currency]
  )

  const onFinish: FormProps['onFinish'] = async (values) => {
    try {
      setLoading(true)
      const response = await api.order.create({
        ...values,
        payment_method: values.payment_method[0],
        products: basket?.map((product) => ({
          product_id: product._id,
          size_id: product.selected_size,
          color_id: product.selected_color,
          amount: product.count
        }))
      })
      const payResponse = api.order
        .pay({
          order_id: response.data._id,
          payment_method: values.payment_method[0]
        })
        .then((res) => {
          if (values.payment_method[0] === 'atmos')
            window.open(res.data, '_blank')
        })
        .catch((err) => message.error(err.message))
      setOrder(response.data)
      setBasket([])
      setLoading(false)
      setSuccess(true)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  useEffect(() => {
    form.setFieldsValue(user)
  }, [])

  useEffect(() => {
    console.log(form.getFieldsValue())
  }, [form.getFieldsValue()])

  return {
    state: { form, loading, basket, totalPrice, success, order },
    actions: { setBasket, onFinish, setSuccess }
  }
}

const OrderCreateContext = createContext<any>({ state: {}, actions: {} })

export const OrderCreateContextProvider: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const value = Context()
  return (
    <OrderCreateContext.Provider value={value}>
      {children}
    </OrderCreateContext.Provider>
  )
}

export default function useOrderCreateContext() {
  return useContext<ReturnType<typeof Context>>(OrderCreateContext)
}
