import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Flex, Row, Space, Tag } from 'antd'
import { Container, Loading, StatusTag } from 'components/ui'
import useOrderInfoContext from '../services/orderInfoContext'
import { format } from 'utils/format'
import useLayoutContext from 'components/ui/layout/services/layoutContext'
import { useNavigate } from 'react-router-dom'
import { faCreditCard } from '@fortawesome/free-regular-svg-icons'
import { faMoneyBill1Wave } from '@fortawesome/free-solid-svg-icons'
import { useTranslation } from 'react-i18next'

const OrderInfo = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    state: { order, loading, loadingAtmos },
    actions: { payAtmos }
  } = useOrderInfoContext()
  const {
    state: { language, currency }
  } = useLayoutContext()
  return loading ? (
    <Loading mode="dark" />
  ) : (
    <Container size="xxl" style={{ margin: '50px auto' }}>
      <Row gutter={[20, 20]}>
        <Col xs={{ order: 2, span: 24 }} md={{ order: 1, span: 14 }}>
          <Flex
            style={{
              padding: '16px 0',
              borderBottom: '2px solid black'
            }}
            justify="space-between"
          >
            <p>{t('order.info.order_details')}</p>
            <StatusTag status={order?.state} />
          </Flex>
          <div style={{ marginTop: 20 }}>
            <p
              style={{
                textTransform: 'uppercase',
                color: 'rgba(11, 153, 255, 1)'
              }}
            >
              {t('order.info.recipients_details')}
            </p>
            <Row gutter={[15, 15]} style={{ marginTop: 15 }}>
              <Col xs={24} sm={12}>
                <p style={{ color: 'rgba(153, 153, 153, 1)' }}>
                  {t('order.info.form.name')}
                </p>
                <p>{order?.name}</p>
              </Col>
              <Col xs={24} sm={12}>
                <p style={{ color: 'rgba(153, 153, 153, 1)' }}>
                  {t('order.info.form.surname')}
                </p>
                <p>{order?.surname}</p>
              </Col>
              <Col xs={24} sm={12}>
                <p style={{ color: 'rgba(153, 153, 153, 1)' }}>
                  {t('order.info.form.phone_number')}
                </p>
                <p>{order?.phone_number}</p>
              </Col>
              <Col xs={24} sm={12}>
                <p style={{ color: 'rgba(153, 153, 153, 1)' }}>
                  {t('order.info.form.email')}
                </p>
                <p>{order?.email}</p>
              </Col>
            </Row>
          </div>

          <div style={{ marginTop: 20 }}>
            <p
              style={{
                textTransform: 'uppercase',
                color: 'rgba(11, 153, 255, 1)'
              }}
            >
              {t('order.info.address_details')}
            </p>
            <Row gutter={[15, 15]} style={{ marginTop: 15 }}>
              <Col xs={24} sm={12}>
                <p style={{ color: 'rgba(153, 153, 153, 1)' }}>
                  {t('order.info.form.country')}
                </p>
                <p>{order?.country}</p>
              </Col>
              <Col xs={24} sm={12}>
                <p style={{ color: 'rgba(153, 153, 153, 1)' }}>
                  {t('order.info.form.region')}
                </p>
                <p>{order?.region}</p>
              </Col>
              <Col xs={24} sm={12}>
                <p style={{ color: 'rgba(153, 153, 153, 1)' }}>
                  {t('order.info.form.city')}
                </p>
                <p>{order?.city}</p>
              </Col>
              <Col xs={24} sm={12}>
                <p style={{ color: 'rgba(153, 153, 153, 1)' }}>
                  {t('order.info.form.street')}
                </p>
                <p>{order?.street}</p>
              </Col>
              <Col xs={24} sm={12}>
                <p style={{ color: 'rgba(153, 153, 153, 1)' }}>
                  {t('order.info.form.postcode')}
                </p>
                <p>{order?.postcode}</p>
              </Col>
            </Row>
          </div>

          <div style={{ marginTop: 20 }}>
            <p
              style={{
                textTransform: 'uppercase',
                color: 'rgba(11, 153, 255, 1)'
              }}
            >
              {t('order.info.payment')}
            </p>
            <div
              style={{
                backgroundColor: 'rgba(240, 238, 237, 1)',
                borderRadius: '15px',
                padding: '15px',
                marginTop: '15px'
              }}
            >
              {order?.payment_method === 'cash' && (
                <Button
                  type="default"
                  style={{
                    border: 'none',
                    padding: '30px 25px',
                    borderRadius: '10px'
                  }}
                  disabled={order.is_paid}
                >
                  <FontAwesomeIcon icon={faMoneyBill1Wave} />{' '}
                  {t('order.info.cash')}
                </Button>
              )}
              {order?.payment_method === 'atmos' && (
                <Button
                  type="default"
                  style={{
                    border: 'none',
                    padding: '30px 25px',
                    borderRadius: '10px'
                  }}
                  disabled={order.is_paid}
                  onClick={() => payAtmos(order._id)}
                  loading={loadingAtmos}
                >
                  <FontAwesomeIcon icon={faCreditCard} /> {t('order.info.card')}
                </Button>
              )}
              <Flex justify="space-between" style={{ marginTop: 15 }}>
                <p style={{ color: 'rgba(111, 111, 111, 1)' }}>
                  {t('order.info.total_price')}:
                </p>
                <p>
                  {order?.is_paid
                    ? format.money(order?.total_price, currency)
                    : '-'}
                </p>
              </Flex>
            </div>
          </div>
        </Col>
        <Col xs={{ order: 1, span: 24 }} md={{ order: 2, span: 10 }}>
          <Flex
            style={{
              backgroundColor: 'rgba(240, 238, 237, 1)',
              padding: 15,
              border: '2px solid rgba(240, 238, 237, 1)'
            }}
            justify="space-between"
          >
            <p>{t('order.info.products')}</p>
          </Flex>
          <Space
            direction="vertical"
            styles={{ item: { width: '100%' } }}
            style={{
              overflowY: 'auto',
              border: '2px solid rgba(240, 238, 237, 1)',
              padding: 15,
              width: '100%'
            }}
          >
            {order?.products?.map((product) => (
              <div>
                <Flex gap={20}>
                  <img
                    src={`${process.env.REACT_APP_FILE_URL}${product.product.images?.find((image) => (product.color_id ? image.color_id === product.color_id : image))?.source_url}`}
                    alt={product.product.main_image.source_url}
                    style={{
                      objectFit: 'cover',
                      width: '100px',
                      height: '130px',
                      display: 'block',
                      flexShrink: 0
                    }}
                  />
                  <Flex justify="space-between" vertical>
                    <div>
                      <p style={{ color: 'black' }}>
                        {format.money(product?.price, currency)}
                      </p>
                      <p
                        style={{
                          color: 'rgba(111, 111, 111, 1)',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          navigate(`/product/${product.product_id}`)
                        }}
                      >
                        {product.product.name[language]}
                      </p>
                      <p style={{ color: 'rgba(189, 189, 189, 1)' }}>
                        {t('common.size')}: {product.size?.code}
                      </p>
                      <p style={{ color: 'rgba(189, 189, 189, 1)' }}>
                        {t('common.amount')}: {product.amount}
                      </p>
                    </div>
                  </Flex>
                </Flex>
              </div>
            ))}
          </Space>
        </Col>
      </Row>
    </Container>
  )
}

export default OrderInfo
