import {
  faCheck,
  faDollar,
  faMinus,
  faMoneyBill1Wave,
  faPlus,
  faTrash,
  faXmark
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Flex, Form, Input, Modal, Row, Space } from 'antd'
import { IProduct } from 'common/types/products'
import { IUser } from 'common/types/user'
import PhoneNumberInput from 'components/form/phoneNumberInput/PhoneNumberInput'
import { Container } from 'components/ui'
import useLayoutContext from 'components/ui/layout/services/layoutContext'
import { useNavigate } from 'react-router-dom'
import useLocalStorageState from 'use-local-storage-state'
import { format } from 'utils/format'
import useOrderCreateContext from '../services/orderCreateContext'
import {
  faCheckCircle,
  faCreditCard
} from '@fortawesome/free-regular-svg-icons'
import { MultipleChoose } from 'components/form'
import paymeLogo from 'assets/images/payme.svg'
import clickLogo from 'assets/images/click.svg'
import useConverter from 'hooks/useConverter'
import { useTranslation } from 'react-i18next'

const OrderCreate = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { converter } = useConverter()

  const handleMinus = (product: IProduct) => {
    setBasket((prev) =>
      prev.map((item) =>
        item._id === product?._id &&
        item.selected_size === product.selected_size &&
        item.selected_color === product.selected_color
          ? {
              ...item,
              count: item?.count && item?.count > 1 ? item?.count - 1 : 1
            }
          : item
      )
    )
  }

  const handlePlus = (product: IProduct) => {
    setBasket((prev) =>
      prev.map((item) =>
        item._id === product?._id &&
        item.selected_size === product.selected_size &&
        item.selected_color === product.selected_color
          ? {
              ...item,
              count: (item?.count ?? 0) + 1
            }
          : item
      )
    )
  }

  const {
    state: { currency, language }
  } = useLayoutContext()

  const {
    state: { form, loading, basket, totalPrice, success, order },
    actions: { setBasket, onFinish, setSuccess }
  } = useOrderCreateContext()
  return (
    <Container size="xxl" style={{ margin: '50px auto' }}>
      <Row gutter={[20, 20]}>
        <Col xs={{ order: 2, span: 24 }} md={{ order: 1, span: 14 }}>
          <Flex
            style={{
              padding: '16px 0',
              borderBottom: '2px solid black'
            }}
            justify="space-between"
          >
            <p>
              {t('order.create.basket')} ({basket?.length ?? 0})
            </p>
          </Flex>
          <Form
            form={form}
            name="get-in-touch"
            layout="vertical"
            onFinish={onFinish}
          >
            <div style={{ marginTop: 20 }}>
              <p
                style={{
                  textTransform: 'uppercase',
                  color: 'rgba(11, 153, 255, 1)'
                }}
              >
                {t('order.create.recipients_details')}
              </p>
              <Row gutter={[15, 15]} style={{ marginTop: 15 }}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: t('order.create.form.name_message')
                      }
                    ]}
                    name="name"
                    style={{ margin: 0 }}
                  >
                    <Input placeholder={t('order.create.form.name')} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: t('order.create.form.surname_message')
                      }
                    ]}
                    name="surname"
                    style={{ margin: 0 }}
                  >
                    <Input placeholder={t('order.create.form.surname')} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: t('order.create.form.phone_number_message')
                      }
                    ]}
                    name="phone_number"
                    style={{ margin: 0 }}
                  >
                    <PhoneNumberInput name="phone_number" form={form} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: t('order.create.form.email_message')
                      }
                    ]}
                    name="email"
                    style={{ margin: 0 }}
                  >
                    <Input placeholder="jhondoe@gmail.com" />
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <div style={{ marginTop: 20 }}>
              <p
                style={{
                  textTransform: 'uppercase',
                  color: 'rgba(11, 153, 255, 1)'
                }}
              >
                {t('order.create.address_details')}
              </p>
              <Row gutter={[15, 15]} style={{ marginTop: 15 }}>
                <Col xs={24} sm={12}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: t('order.create.form.country_message')
                      }
                    ]}
                    name="country"
                    style={{ margin: 0 }}
                  >
                    <Input placeholder={t('order.create.form.country')} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: t('order.create.form.region_message')
                      }
                    ]}
                    name="region"
                    style={{ margin: 0 }}
                  >
                    <Input placeholder={t('order.create.form.region')} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: t('order.create.form.city_message')
                      }
                    ]}
                    name="city"
                    style={{ margin: 0 }}
                  >
                    <Input placeholder={t('order.create.form.city')} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: t('order.create.form.street_message')
                      }
                    ]}
                    name="street"
                    style={{ margin: 0 }}
                  >
                    <Input placeholder={t('order.create.form.street')} />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: t('order.create.form.postcode_message')
                      }
                    ]}
                    name="postcode"
                    style={{ margin: 0 }}
                  >
                    <Input placeholder={t('order.create.form.postcode')} />
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <div style={{ marginTop: 20 }}>
              <p
                style={{
                  textTransform: 'uppercase',
                  color: 'rgba(11, 153, 255, 1)'
                }}
              >
                {t('order.create.payment')}
              </p>
              <div
                style={{
                  backgroundColor: 'rgba(240, 238, 237, 1)',
                  borderRadius: '15px',
                  padding: '15px',
                  marginTop: '15px'
                }}
              >
                {/* <Flex gap={15}>
                  <Input placeholder="Promocode" />
                  <Button
                    type="primary"
                    style={{ backgroundColor: 'rgba(111, 111, 111, 1)' }}
                  >
                    Apply
                  </Button>
                </Flex> */}
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: t('order.create.form.payment_method_message')
                    }
                  ]}
                  name="payment_method"
                  style={{ margin: 0 }}
                >
                  <MultipleChoose
                    name="payment_method"
                    form={form}
                    options={[
                      {
                        label: (
                          <p>
                            <FontAwesomeIcon icon={faMoneyBill1Wave} />{' '}
                            {t('order.create.cash')}
                          </p>
                        ),
                        value: 'cash'
                      },
                      {
                        label: (
                          <>
                            <FontAwesomeIcon icon={faCreditCard} />{' '}
                            {t('order.create.card')}
                          </>
                        ),
                        value: 'atmos'
                        // disabled: true
                      },
                      {
                        label: <img src={paymeLogo} width={50} />,
                        value: 'payme',
                        disabled: true
                      },
                      {
                        label: <img src={clickLogo} width={50} />,
                        value: 'click',
                        disabled: true
                      }
                    ]}
                    buttonStyle={{
                      border: 'none',
                      padding: '30px 25px',
                      borderRadius: '10px'
                    }}
                    multiple={false}
                    onClick={(value) =>
                      form.setFieldValue('payment_method', value)
                    }
                  />
                </Form.Item>
                <Flex justify="space-between" style={{ marginTop: 15 }}>
                  <p style={{ color: 'rgba(111, 111, 111, 1)' }}>
                    {t('order.create.total_price')}:
                  </p>
                  <p>{format.money(totalPrice, currency)}</p>
                </Flex>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ marginTop: '15px' }}
                  loading={loading}
                  disabled={!(basket.length > 0)}
                  block
                >
                  {t('order.create.form.submit')}
                </Button>
              </div>
            </div>
          </Form>
        </Col>
        <Col xs={{ order: 1, span: 24 }} md={{ order: 2, span: 10 }}>
          <Flex
            style={{
              backgroundColor: 'rgba(240, 238, 237, 1)',
              padding: 15,
              border: '2px solid rgba(240, 238, 237, 1)'
            }}
            justify="space-between"
          >
            <p>{t('order.create.products')}</p>
            <p
              style={{
                color: 'rgba(153, 153, 153, 1)',
                cursor: 'pointer'
              }}
              onClick={() => setBasket([])}
            >
              {t('order.create.clear_basket')}{' '}
              <FontAwesomeIcon icon={faTrash} />
            </p>
          </Flex>
          <Space
            direction="vertical"
            styles={{ item: { width: '100%' } }}
            style={{
              overflowY: 'auto',
              border: '2px solid rgba(240, 238, 237, 1)',
              padding: 15,
              width: '100%'
            }}
          >
            {basket.map((product) => (
              <div>
                <Flex gap={20}>
                  <img
                    src={`${process.env.REACT_APP_FILE_URL}${product?.images.find((image) => (product.selected_color ? image.color_id === product.selected_color : image))?.source_url}`}
                    alt={product.main_image.source_url}
                    style={{
                      objectFit: 'cover',
                      width: '100px',
                      height: '130px',
                      display: 'block',
                      flexShrink: 0
                    }}
                  />
                  <Flex justify="space-between" vertical>
                    <div>
                      <p style={{ color: 'black' }}>
                        {product?.integration?.find(
                          (item) =>
                            item.size_id === product.selected_size &&
                            item.color_id === product.selected_color
                        )
                          ? format.money(
                              converter(
                                product?.integration?.find(
                                  (item) =>
                                    item.size_id === product.selected_size &&
                                    item.color_id === product.selected_color
                                )?.price,
                                product?.integration?.find(
                                  (item) =>
                                    item.size_id === product.selected_size &&
                                    item.color_id === product.selected_color
                                )?.currency_id,
                                currency
                              ),
                              currency
                            )
                          : t('products.no_price')}
                      </p>
                      <p
                        style={{
                          color: 'rgba(111, 111, 111, 1)',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          navigate(`/product/${product._id}`)
                        }}
                      >
                        {product.name[language]}
                      </p>
                      <p style={{ color: 'rgba(189, 189, 189, 1)' }}>
                        {t('common.size')}:{' '}
                        {
                          product.sizes?.find(
                            (size) => size._id === product.selected_size
                          )?.code
                        }
                      </p>
                    </div>
                    <Space className="counters">
                      <Button
                        icon={<FontAwesomeIcon icon={faMinus} />}
                        onClick={() => handleMinus(product)}
                      />
                      <Button>{product.count ?? 0}</Button>
                      <Button
                        icon={<FontAwesomeIcon icon={faPlus} />}
                        onClick={() => handlePlus(product)}
                      />
                    </Space>
                  </Flex>
                  <Button
                    icon={<FontAwesomeIcon icon={faXmark} />}
                    shape="circle"
                    type="text"
                    styles={{ icon: { color: 'rgba(153, 153, 153, 1)' } }}
                    onClick={() =>
                      setBasket(
                        basket.filter(
                          (item) =>
                            !(
                              item._id === product?._id &&
                              item.selected_size === product.selected_size &&
                              item.selected_color === product.selected_color
                            )
                        )
                      )
                    }
                  />
                </Flex>
              </div>
            ))}
          </Space>
        </Col>
      </Row>
      <Modal open={success} closeIcon={false} footer={false} centered>
        <Flex justify="center" align="center" vertical gap={15}>
          <FontAwesomeIcon
            icon={faCheckCircle}
            size="2xl"
            style={{ color: 'rgba(40, 205, 65, 1)' }}
          />
          <h2 className="cormorant-sc-medium" style={{ textAlign: 'center' }}>
            {t('order.create.orderSuccess.title', {
              orderNumber: order?.number
            })}
          </h2>
          <p style={{ color: 'rgba(153, 153, 153, 1)' }}>
            {t('order.create.orderSuccess.checkStatus')}
          </p>
          <Button
            type="primary"
            block
            style={{ marginTop: '20px' }}
            onClick={() => {
              navigate('/profile/purchases')
              setSuccess(false)
            }}
          >
            {t('order.create.orderSuccess.goToOrders')}
          </Button>
          <Button
            type="text"
            block
            onClick={() => {
              navigate('/')
              setSuccess(false)
            }}
          >
            {t('order.create.orderSuccess.goToMainPage')}
          </Button>
        </Flex>
      </Modal>
    </Container>
  )
}

export default OrderCreate
