import api from 'common/api'
import { IOrder } from 'common/types/order'
import React, {
  FC,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react'
import { useParams } from 'react-router-dom'

const Context = () => {
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [loadingAtmos, setLoadingAtmos] = useState(false)
  const [order, setOrder] = useState<IOrder>()

  const getById = async (id: string) => {
    try {
      setLoading(true)
      const response = await api.order.getById(id)
      setOrder(response.data)
      setLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  const payAtmos = async (_id: string) => {
    try {
      setLoadingAtmos(true)
      const response = await api.order.pay({
        payment_method: 'atmos',
        order_id: _id
      })
      window.open(response.data, '_blank')
      setLoadingAtmos(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }

  useEffect(() => {
    if (id) getById(id)
  }, [id])
  return {
    state: { order, loading, loadingAtmos },
    actions: { payAtmos }
  }
}

const OrderInfoContext = createContext<any>({ state: {}, actions: {} })

export const OrderInfoContextProvider: FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const value = Context()
  return (
    <OrderInfoContext.Provider value={value}>
      {children}
    </OrderInfoContext.Provider>
  )
}

export default function useOrderInfoContext() {
  return useContext<ReturnType<typeof Context>>(OrderInfoContext)
}
